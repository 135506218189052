import React from 'react';
import DefaultLayout from '../layouts/DefaultLayout';
import illustrationYeti from '../images/illustration-yeti.gif';

export default () => (
  <DefaultLayout
    pageTitle="Page not found"
    pageDescription=""
    bodyClass="home"
  >
    <div className="container">
      <section className="center">
        <figure>
          <img src={illustrationYeti} alt="Running yeti" />
        </figure>
        <h2>
          Sorry, there’s nothing there. <br />
          Just you and me.
        </h2>
      </section>
    </div>
  </DefaultLayout>
);
